<template>
  <div>
    <div class="mb-3">
      <label  for="emailField"  class="form-label">E-Mail</label>
      <input type="email" :class="{'form-control': true, 'is-invalid': errors.email}"  id="emailField" v-model="user.email" >
      <div class="invalid-feedback" v-if="errors.email">
        <p v-for="(item, x) in errors.email" :key='x' >{{ errors.email[x] }}</p>
      </div>
    </div>      
    <label  for="passwordField" class="form-label">Passwort</label>
    <div class="mb-3">
      <input type="password" :class="{'form-control': true, 'is-invalid': errors.password}"  id="passwordField" v-model="user.password" >
      <div class="invalid-feedback" v-if="errors.password">
        <p v-for="(item, x) in errors.password" :key='x' >{{ errors.password[x] }}</p>
      </div>
    </div>
    <div class="mb-3">
      <button v-on:click.prevent="signIn" class="form-control btn btn-primary">Einlogen</button>
    </div>
  </div>    
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: { type: Function },
      setLoginStatus: {type:Function},
      setUserData: {type:Function},
      authFunction: {type:Function},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        errors:{},
        user:{
          email:'',
          password:'',
          token:''
        }
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async signIn(){
        console.log('Einlogen');
        let response;
        response = await this.apiData('Login.vue login()','post','auth/login', this.user);

        if(typeof response === 'object'){
          if(response.token){
            this.setItem('access_token', response.token).then(data => {
            this.setLoginStatus(true);
            console.log(response);
            this.setUserData();
            this.$toast.open({
              message: 'Login erfolgreich',
              type: 'success',
              duration:3000,
            });
            this.$router.push('/');
            return data;
            });
          }else{
            this.$toast.open({
              message: 'Login fehlgeschlagen',
              type: 'error',
              duration:3000,
            });
          }
        }else{
            this.$toast.open({
              message: 'Login fehlgeschlagen',
              type: 'error',
              duration:3000,
            });
          }
          this.authFunction();
      },
      setItem: async function (key, value) {
        let tina = await localStorage.setItem(key, value);
        return tina;
      },

    },
  };
</script>
<style lang="scss">
</style>