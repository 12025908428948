<template>
    <div class="inlineWindow">
      <div class="card">
        <h5 class="card-header">Datenschutz und AGB</h5>
        <div class="card-body">
          
          <h2>Datenschutzverordnung</h2>
          <p>Wir, [Deine Website], nehmen den Schutz Ihrer persönlichen Daten sehr ernst und halten uns strikt an die Regeln der Datenschutzgesetze. Personenbezogene Daten werden auf dieser Website nur im technisch notwendigen Umfang erhoben. In keinem Fall werden die erhobenen Daten verkauft oder aus anderen Gründen an Dritte weitergegeben.</p>
          
          <h3>1. Erhebung und Verarbeitung von Daten</h3>
          <p>Wir erheben und verarbeiten personenbezogene Daten, die Sie uns freiwillig zur Verfügung stellen, beispielsweise bei der Registrierung auf unserer Website oder bei der Nutzung unserer Dienstleistungen.</p>
          
          <h3>2. Nutzung und Weitergabe personenbezogener Daten</h3>
          <p>Die von Ihnen zur Verfügung gestellten personenbezogenen Daten verwenden wir ausschließlich, um Ihre Anfragen zu beantworten, Verträge mit Ihnen abzuschließen und abzuwickeln, und für die technische Administration.</p>
          
          <h3>3. Auskunftsrecht</h3>
          <p>Sie haben das Recht, jederzeit Auskunft über die bezüglich Ihrer Person gespeicherten Daten, deren Herkunft und Empfänger sowie den Zweck der Speicherung zu verlangen.</p>
          
          <h3>4. Sicherheit Ihrer Daten</h3>
          <p>Wir sind bemüht, Ihre personenbezogenen Daten durch Ergreifung aller technischen und organisatorischen Möglichkeiten so zu speichern, dass sie für Dritte nicht zugänglich sind. Bei der Kommunikation per E-Mail kann die vollständige Datensicherheit von uns jedoch nicht gewährleistet werden.</p>
          
          <h3>5. Änderungen dieser Datenschutzverordnung</h3>
          <p>Wir behalten uns vor, diese Datenschutzverordnung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzverordnung umzusetzen.</p>

          <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>
          
          <h3>1. Geltungsbereich</h3>
          <p>Diese Allgemeinen Geschäftsbedingungen gelten für alle Geschäftsbeziehungen zwischen [Deine Website] und ihren Kunden in der jeweils zum Zeitpunkt des Vertragsschlusses gültigen Fassung.</p>
          
          <h3>2. Vertragsschluss</h3>
          <p>Die Darstellung der Produkte und Dienstleistungen auf unserer Website stellt kein rechtlich bindendes Angebot, sondern einen unverbindlichen Online-Katalog dar. Durch Anklicken des Bestellbuttons geben Sie eine verbindliche Bestellung der im Warenkorb enthaltenen Waren ab.</p>
          
          <h3>3. Preise und Zahlungsbedingungen</h3>
          <p>Die angegebenen Preise sind Endpreise und enthalten die gesetzliche Mehrwertsteuer. Die Zahlung erfolgt wahlweise per Vorkasse, Kreditkarte oder einem anderen auf der Website angebotenen Zahlungsmittel.</p>
          
          <h3>4. Lieferung und Versandkosten</h3>
          <p>Die Lieferung erfolgt an die von Ihnen angegebene Lieferadresse. Die Versandkosten werden Ihnen im Bestellprozess deutlich mitgeteilt.</p>
          
          <h3>5. Widerrufsrecht</h3>
          <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie die Ware erhalten haben.</p>
          
          <h3>6. Gewährleistung</h3>
          <p>Es gelten die gesetzlichen Gewährleistungsrechte.</p>
          
          <h3>7. Haftung</h3>
          <p>Wir haften uneingeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer vorsätzlichen oder fahrlässigen Pflichtverletzung von uns beruhen. Für sonstige Schäden haften wir nur bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung.</p>
          
          <h3>8. Schlussbestimmungen</h3>
          <p>Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam sein oder den gesetzlichen Regelungen widersprechen, so wird hierdurch der Vertrag im Übrigen nicht berührt.</p>
            
        </div>
        <div class="card-footer text-body-secondary">
          <a href="#" class="btn btn-primary" @click.prevent="agbShowFunction">Schliessen</a>
        </div>
      </div>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      agbShowFunction: { type: Function }
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
.inlineWindow{
  width:100vw;
  height:100vh;
  position: fixed;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #fff, $alpha: .8);
}
.inlineWindow .card{
  width:60vw;
  height:80vh;
}
.inlineWindow .card-body{
  overflow-y: scroll;
}
</style>