<template>
    <div class="modalOverlay" v-if="warning.visible">
      <div class="card">
        <div class="card-header">
          Warnung
        </div>
        <div class="card-body">
          <p class="card-text">{{warning.text}}</p>
          
        </div>
        <div class="card-footer d-flex flex-row-reverse">
          <a href="#" class="btn btn-danger" @click="deleteItem(warning.deleteObject)" >Löschen</a> &nbsp;
          <a href="#" class="btn btn-primary" @click="cancleWarning">Abbrechen</a> &nbsp;
        </div>
      </div>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      deleteItem:{ type: Function },
      cancleWarning:{ type: Function },
      warning: { required: true }
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
       
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
.modalOverlay{
  width:100vw;
  height:100vh;
  background-color: rgba($color: #000000, $alpha: .5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000000000;
  left:0;
  top:0;
}
</style>