<template>
    <div>
      <h1>Imagga</h1>
      <div class="row">
        <div class="col-md-3"  v-for="(item, x) in filesContent" :key='x'>
          <div class="card" >
            <div class="cardImg"  :style="{ backgroundImage: 'url(' + item.src + ')', width:'100%', height:'200px' }"></div>
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p v-if="item.loadingKeywords" class="text-center">
              <img src="@/assets/loader.gif"  />
              </p>
              <p>
                <template  v-if="item.keywords != null" >
                <span class="badge bg-secondary" v-for="(keyword, y) in filesContent[x].keywords" :key='y' style="margin-right:5px">{{keyword.tag.de}}</span>&nbsp;
              </template>
              </p>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <button class="btn btn-primary" @click="imaggaData(item.src, x)">GET KEYWORDS</button>
              <button class="btn btn-primary" @click="imaggaText(item.src, x)">GET TEXT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
//import  fs  from 'fs-web';
//import fs from 'vue-server-renderer'



  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData:{ type: Function }
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        filesContent:null,
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    async mounted() {
      await this.getFiles();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {

      async imaggaData(img, id){
      let apiReturn;
      let language = 'de,en'
      var config = {
        method: 'get',
        url: 'https://api.imagga.com/v2/tags?language='+language+'&image_url=' + img +'&threshold=30',
        auth: {
          username:'acc_337f95627c21b3e',
          password:'8b6dcb0af64f2e8677a754589f92e4ff'
        },
       
      };
      this.filesContent[id].loadingKeywords = true;
      await axios(config).then((response) => {
        if(!response){
          return 'keine API daten';
        }else{
          apiReturn = response.data;
          
          this.filesContent[id].loadingKeywords = false;
          this.filesContent[id].keywords = apiReturn.result.tags;
        }
        }).catch(function (error) {
          return error;
        });
      //return apiReturn;
      console.log(apiReturn.result.tags);
    },
    async imaggaText(img, id){
      let apiReturn;
      
      var config = {
        method: 'get',
        //url: 'https://api.imagga.com/v2/text?image_url=' + img,
        url: 'https://api.imagga.com/v2/https://api.imagga.com/v2/text?image_url=https://imagga.com/static/images/technology/sample_text.jpg',
        auth: {
          username:'acc_337f95627c21b3e',
          password:'8b6dcb0af64f2e8677a754589f92e4ff'
        },
       
      };
      //this.filesContent[id].loadingText = true;
      await axios(config).then((response) => {
        if(!response){
          return 'keine API daten';
        }else{
          apiReturn = response;
          
          //this.filesContent[id].loadingText = false;
          //this.filesContent[id].text = apiReturn.result.tags;
        }
        }).catch(function (error) {
          console.log("---------------------------------------------------------------------");
          console.log(error);
          return error;
        });
      //return apiReturn;
      console.log(id);
      console.log(img);
      console.log(apiReturn);
    },








    async getFiles(){
        let response;
        response = await this.apiData('FilesView.vue getFiles()','get','files');
        console.log("API RESPONSE");
        console.log(response);
        this.filesContent = response;
          
        for(let i = 0 ; i < this.filesContent.length; i++) {
          this.filesContent[i].src = "https://api.haselabs.ch/storage/img/"+this.filesContent[i].owner+"/"+this.filesContent[i].name;
            this.filesContent[i].id = this.filesContent[i].id.toString();
            this.filesContent[i].created_at_readable = moment(String(this.filesContent[i].created_at)).format('DD.MM.YYYY HH:mm');
            this.filesContent[i].updated_at_readable = moment(String(this.filesContent[i].updated_at)).format('DD.MM.YYYY HH:mm');
            this.filesContent[i].created_at_unix = moment(String(this.filesContent[i].created_at)).format('x');
            this.filesContent[i].updated_at_unix = moment(String(this.filesContent[i].updated_at)).format('x');
            this.filesContent[i].loadingKeywords = false;
            this.filesContent[i].keywords = null;

        }

       
      },



    },
  };
</script>
<style lang="scss">
.cardImg{
  background-size: cover;
  background-position: center center;
}
.card{
  margin-bottom:20px;
}
</style>