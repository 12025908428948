<template>
  <AgbDatenschutz :agbShowFunction="agbShowFunction" v-if="agbShow"/>
  <div class="container">
    <!-- Login & Register Panel -->
    <div class="card " v-if="isAuthenticated === false">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a :class="{'nav-link':true,  'active': loginNav==='login' }" aria-current="true" href="#"  @click.prevent="loginNav = 'login'">Login</a>
          </li>
          <li class="nav-item">
            <a :class="{'nav-link':true,  'active': loginNav==='register' }" href="#" @click.prevent="loginNav = 'register'">Registrieren</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="row" v-if="loginNav === 'login'">
          <div class="col-md-6">
            <h4>Login</h4>
            <p>Willkommen zurück! Bitte melden Sie sich mit Ihren Zugangsdaten an, um auf Ihr Konto zuzugreifen und alle Funktionen unserer Website nutzen zu können.</p>
            <p>Geben Sie einfach Ihre E-Mail-Adresse und Ihr Passwort in die Felder ein und klicken Sie auf "Anmelden".</p>
        
            <p>Falls Sie noch kein Konto haben, <a href="#" @click.prevent="loginNav = 'register'">registrieren Sie sich hier</a>.</p>
          </div>
          <div class="col-md-6">
            <Login 
            :setLoginStatus="setLoginStatus"
            :apiData="apiData"
            :setUserData="setUserData" 
            :authFunction="authFunction"
            />
          </div>
        </div>
        <div class="row" v-if="loginNav === 'register'">
          <div class="col-md-6">
            <h4> Registrieren Sie sich jetzt</h4>
            <p>Um die volle Funktionalität unserer Website nutzen zu können, müssen Sie sich zuerst registrieren. Ohne Registrierung ist der Zugriff auf unsere Inhalte und Dienstleistungen leider nicht möglich.</p>
            <h5>Warum registrieren?</h5>
            <ul>
                <li><strong>Zugang zu allen Inhalten:</strong> Nur registrierte Benutzer können alle Bereiche und Inhalte unserer Website nutzen.</li>
            </ul>          
            <h5>So einfach geht's:</h5>
            <ol>
                <li><strong>Formular ausfüllen:</strong> Geben Sie Ihre persönlichen Daten in das untenstehende Registrierungsformular ein.</li>
                <li><strong>Bestätigungscode erhalten:</strong> Nach der Registrierung senden wir Ihnen einen vierstelligen Bestätigungscode per E-Mail.</li>
                <li><strong>Code eingeben:</strong> Geben Sie diesen Code bei Ihrer ersten Anmeldung ein, um Ihr Konto zu verifizieren.</li>
            </ol>
          </div>
          <div class="col-md-6">
            <Register        
            :apiData="apiData"
            :agbShowFunction="agbShowFunction"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- END Login & Register Panel -->
    <!-- Verification Panel -->
    <div class="card text-center" v-if="isAuthenticated ===true && isVerified === false">
      <div class="card-header">
        <h5 class="card-title">E-Mail Adresse Bestätigen</h5>
      </div>
      <div class="card-body">      
        <p class="card-text">Um Ihre E-Mail-Adresse zu verifizieren, geben Sie bitte den 4-stelligen Code ein, den wir Ihnen per E-Mail zugeschickt haben.</p>
        <h6 class="card-title">Code eingeben:</h6>
        <v-otp-input
          style="display: flex;justify-content: center;"
          ref="otpInput"
          input-classes="otp-input"
          :conditionalClass="['one', 'two', 'three', 'four']"
          separator="-"
          inputType="letter-numeric"
          :num-inputs="4"
          v-model:value="bindValue"
          :should-auto-focus="true"
          :should-focus-order="true"
          @on-complete="handleOnComplete"
        />
        <h5 class="text-danger" v-if="verifyError">Ihre eingabe ist falsch!</h5>
        <p class="card-text">Haben Sie keinen Code erhalten? Überprüfen Sie bitte Ihren Spam-Ordner oder <a href="#">fordern Sie einen neuen Code an.</a></p>
      </div>
    </div>
    <!-- END Verification Panel -->
  </div>






  <!-- User Header -->
  <MainHeader
  v-if="isAuthenticated && isVerified" 
  :isAuthenticated="isAuthenticated"
  :userData="userData"
  :setLoginStatus="setLoginStatus" 
  />
  <!-- End User Header -->
  <!-- User Router -->
  <div class="container">
    <router-view 
    v-if="isAuthenticated && isVerified" 
    :apiData="apiData" 
    :setLoginStatus="setLoginStatus" 
    :setUserData="setUserData" 
    :userData="userData" 
    :config="config" 
    :isAuthenticated="isAuthenticated" 
    :isVerified="isVerified" 
    :authFunction="authFunction"
    />
  </div>
  <!-- End User Router -->
</template>
<script>
import AgbDatenschutz from '@/components/AgbDatenschutz.vue';
import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';
import VOtpInput from "vue3-otp-input";

import config from './config.json'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import MainHeader from '@/components/MainHeader.vue'
import axios from 'axios';
export default {
  name: '${COMPONENT_NAME}',
  components: {
    AgbDatenschutz,
    Login,
    Register,
    VOtpInput,

    MainHeader
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      agbShow:false,
      verifyError:false,
      userDataLocal: this.userData,
      loginNav:'login',
      bindValue:"",

      config: config,
      isAuthenticated:null,
      isVerified:null,
      userData:{},
      workingmodel:{},
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // async created() {},
  async beforeMount() {
    /*
    let checkForToken =  localStorage.getItem('access_token');
    if(checkForToken){
      this.userData = await this.apiData('App.vue beforeMount()', 'get','user');
      if(this.userData){
        this.isAuthenticated = true;
      }else{
        this.isAuthenticated = false;
      }
       localStorage.setItem('userUUID', this.userData.uuid);      
    }else{
      this.isAuthenticated = false;
    }
    if(await this.userData.email_verified_at == null){
        this.isVerified = false;
    }else{
        this.isVerified = true;
    }
    */
    this.authFunction();
  },
  // mounted() {},
  // async beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    //AGB
    agbShowFunction(){
      if(this.agbShow === false){
        this.agbShow = true;
      }else if(this.agbShow === true){
        this.agbShow = false;
      }
      
    },
    // Set User Data zum vererben
    async setUserData(){
        this.userData = await this.apiData('App.vue setUserData()','get','user');
    },
    // Set Login Status
    setLoginStatus(status){
      this.isAuthenticated = status;
    },
    //API Data zum vererben
    async apiData(sender, action, endPoint, data){
      console.log('Access API Data from: ' + sender);
      let apiReturn;
      var config = {
        method: action,
        url: this.config.apiURL+'api/'+this.config.apiVersion+'/'+endPoint,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        },
      };
      await axios(config).then((response) => {
        if(!response.data){
          return 'keine API daten';
        }else{
          apiReturn = response.data;
        }
        }).catch(function (error) {
          return error;
        });
      return apiReturn;
    },
    //Auth Function Zum vererben
    async authFunction(){
      let checkForToken =  localStorage.getItem('access_token');
      if(checkForToken){
        this.userData = await this.apiData('App.vue beforeMount()', 'get','user');
        if(this.userData){
          this.isAuthenticated = true;
        }else{
          this.isAuthenticated = false;
        }
        localStorage.setItem('userUUID', this.userData.uuid);
      }else{
        this.isAuthenticated = false;
      }
      if(await this.userData.email_verified_at == null){
        this.isVerified = false;
      }else{
        this.isVerified = true;
      }
    },
    //Veryfy E-Mail
    async handleOnComplete(){
      let response;
      this.userDataLocal = this.userData;
      this.userDataLocal.code = this.bindValue;
      response = await this.apiData('Login.vue login()','post','auth/veryficate', this.userDataLocal);
      if(response === 1){
        this.authFunction();
        console.log('eingabe richtig');
        this.verifyError = false;
      }else{
        console.log('eingabe falsch')
        this.verifyError = true; 
      }     
    }
  },
};
</script>
<style lang="scss">
  @import "@/scss/app";
</style>