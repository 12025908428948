<template>
    <div>
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">Navbar</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <template  v-for="(item, x) in mainNavigation" :key='x'>
                <li class="nav-item" v v-if="item.display">
                  <a href="#" @click.prevent="mainNav(item, x, 'mainNavigation')" :class="{'nav-link':true, 'active': item.active}">{{item.name}}</a>
                </li>
              </template>
            </ul>
            <div class="loginNavigation" v-if="!isAuthenticated">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li i class="nav-item" v-for="(item, x) in loginNavigation" :key='x'>
                  <a href="#" @click.prevent="mainNav(item, x, 'loginNavigation')" :class="{'nav-link':true, 'active': item.active}">{{item.name}}</a>
                </li>
              </ul>
            </div>
            <div class="userChip" v-if="isAuthenticated" @click="displayUserNavigation = !displayUserNavigation" >
              <div class="userChipIcn">
                <font-awesome-icon icon="fa-solid fa-user" />
              </div>
              <div class="userChipName">{{ userData.name }}</div>
            </div>
          </div>
        </div>
      </nav>

      <div class="card userNavigation" v-if="displayUserNavigation" v-click-outside="closeUserNavigation">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><font-awesome-icon icon="fa-solid fa-address-card" /> Profil</li>
          <li class="list-group-item"><font-awesome-icon icon="fa-solid fa-gear" /> Einstellungen</li>
          <li class="list-group-item" @click="logout"><font-awesome-icon icon="fa-solid fa-right-from-bracket" /> Auslogen</li>
        </ul>
      </div>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      isAuthenticated:{required: true},
      userData:{required: true},
      setLoginStatus:{type: Function},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        displayUserNavigation:false,
        mainNavigation:[
          {
            name:'Home',
            active:true,
            url:'/',
            display:true
          },
          {
            name:'Articles',
            active:false,
            url:'/articles',
            display:true
          },
          {
            name:'Files',
            active:false,
            url:'/files',
            display:true
          },
          {
            name:'Imagga',
            active:false,
            url:'/imagga',
            display:true
          },
         
        ],
        loginNavigation:[
        {
            name:'Register',
            active:false,
            url:'/register'
          },
          {
            name:'Login',
            active:false,
            url:'/login'
          },
          
        ]
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
     updated() {
      this.mainNavigation[1].display = this.isAuthenticated;
     },
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      mainNav(item, x, navType){
        for(let i = 0 ; i < this[navType].length; i++) {
            this[navType][i].active =false;
        }
        this[navType][x].active = true;
        this.$router.push(item.url);
      },
      closeUserNavigation(){
        
        if(this.displayUserNavigation === true){
          this.displayUserNavigation =false;
        }
      },
      logout(){
        localStorage.removeItem('access_token');
        this.setLoginStatus(false);
        this.closeUserNavigation();
        this.$toast.open({
              message: 'Erfolgreich ausgelogt',
              type: 'success',
              duration:3000,
            });
        this.$router.push('/');
      }
    },
  };
</script>
<style lang="scss">
</style>