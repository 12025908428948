<template>
   
  <div>
    <button @click="console.log(dropzoneOptions.url)">URL</button>
    <DeleteWarning :warning="warning" :cancleWarning="cancleWarning" :deleteItem="deleteFile" />


    <vue-dropzone
    ref="myVueDropzone" 
    id="dropzone" 
    :options="dropzoneOptions"
    @vdropzone-success="successAction"
    @vdropzone-error="verror"
  />


  <SortableTable 
      :colums = "colums"
      :rows = "filesContent"
      :itemsPerPage = "itemsCount"
      :initialSortColumn = "sortBy"
      :sortDir = "sortDir"
      :userData="userData"
      :openWarning="openWarning"
      />



  </div>
</template>
<script>
import DeleteWarning from '@/components/DeleteWarning';
import moment from 'moment';
import SortableTable from '@/components/SortableTable';
import vueDropzone from 'vue2-dropzone-vue3'


  export default {
    name: '${COMPONENT_NAME}',
    
    




    components: {
     vueDropzone,
     SortableTable,
     DeleteWarning,
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      config:{ required:true },
      userData:{ required:true },
      apiData:{ type: Function }
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        warning:{
          text:'Wollen Sie das Bild wirklich löschen? Diese Aktion kann nicht widerrufen werden.',
          visible:false,
          deleteObject:{},
        },
        isVisible: true,
        itemsCount: 20,
        sortBy:'created_at_unix',
        sortDir:'desc',
        colums:[
        {
            name:'Bild',
            value:'src',
            type:'image',
            visible:true
          },
          {
            name:'datum',
            value:'created_at_unix',
            valueReadable:'created_at_readable',
            type:'date',
            visible:true
          },
          {
            name:'id',
            value:'id',
            type:'text',
            visible:true
          },
          {
            name:'name',
            value:'name',
            type:'text',
            visible:true
          },
          {
            name:'Original Name',
            value:'orgName',
            type:'text',
            visible:true
          },
        ],
        filesContent:[{name:'name'}],
        localuser:this.userData.uuid,
        dropzoneOptions: {
                    //url: 'https://api.haselabs.ch/api/v1/dropzone',
                    url: this.config.apiURL+'api/'+this.config.apiVersion+'/dropzone',
                    headers: {
                        owner: localStorage.getItem('userUUID'),
                        'Cache-Control': null,
                        'X-Requested-With': null,
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'enctype':'multipart/form-data',
                       
                    },
                    
                    maxFilesize: 100000000000000000000000000000, // MB
                    maxFiles: 40,
                    thumbnailWidth: 150, // px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    acceptedFiles:'image/*, application/pdf',


                },
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {

     

    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    async beforeCreate() {
      
    },
     async created() {
     
     },
     async beforeMount() {
       
     },
    // render(h) { return h(); },
     async mounted() {
      await this.getFiles();
      
      //this.dropzoneOptions.headers.owner =  await this.userData.uuid;
     },
    // beforeUpdate() {},
     updated() {
      //this.dropzoneOptions.headers.owner =   this.userData.uuid;
     },
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {

      openWarning(object){
        this.warning.visible = true;
        this.warning.deleteObject = object;
        console.log(object);
      },
      cancleWarning(){
        this.warning.visible = false;
      },
      async deleteFile(object){
        let response;
        response = await this.apiData('FilesView.vue deleteFile()','post','delete-file', object);
        console.log(response);
        await this.getFiles();
        this.warning.visible = false;
      },
     
        async successAction(file, response){
          await this.getFiles();
          this.$refs.myVueDropzone.removeFile(file);
          console.log(response);
        },
        verror(file, response){

          console.log(file);
          console.log("------------------------------------------------------------------------------------------");
          console.log(response);
          console.log("------------------------------------------------------------------------------------------");
        },
        async getFiles(){
        let response;
        response = await this.apiData('FilesView.vue getFiles()','get','files');
        console.log("API RESPONSE");
        console.log(response);
        this.filesContent = response;
          
        for(let i = 0 ; i < this.filesContent.length; i++) {
          //this.filesContent[i].src = "http://localhost:8000/storage/img/"+this.filesContent[i].owner+"/"+this.filesContent[i].name;
          this.filesContent[i].src = this.config.storageURL+"img/"+this.filesContent[i].owner+"/"+this.filesContent[i].name;
            this.filesContent[i].id = this.filesContent[i].id.toString();
            this.filesContent[i].created_at_readable = moment(String(this.filesContent[i].created_at)).format('DD.MM.YYYY HH:mm');
            this.filesContent[i].updated_at_readable = moment(String(this.filesContent[i].updated_at)).format('DD.MM.YYYY HH:mm');
            this.filesContent[i].created_at_unix = moment(String(this.filesContent[i].created_at)).format('x');
            this.filesContent[i].updated_at_unix = moment(String(this.filesContent[i].updated_at)).format('x');
        }

       
      },
        
    },
  };
</script>
<style lang="scss">
</style>