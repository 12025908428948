import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import toast from 'vue-toast-notification'
import vClickOutside from 'click-outside-vue3'
import { defineComponent } from 'vue';




/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUser, faAddressCard, faRightFromBracket, faGear, faCircleUp, faCircleDown, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(faUser, faAddressCard, faRightFromBracket, faGear, faCircleUp, faCircleDown, faPen, faTrash )

createApp(App).use(router).use(toast).use(vClickOutside).use(defineComponent).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
