<template>
    <div>

      <DeleteWarning :warning="warning" :cancleWarning="cancleWarning" :deleteItem="deleteArticle" />




      <h1>Artikel</h1>
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Artikel erfassen</h5>
        </div>
        <div class="card-body">
          <label  for="titleField"  class="form-label">Titel</label>
          <input type="email" :class="{'form-control': true}"  id="titleField" v-model="newArticle.title" >

          <label for="textField" class="form-label">Text</label>
          <textarea class="form-control" id="textField" rows="3"  v-model="newArticle.text" ></textarea>
  
        </div>
        <div class="card-footer">
            <button v-on:click.prevent="addArticle" class="form-control btn btn-primary">Speichern</button>
        </div>
      </div>

      <SortableTable 
      :colums = "colums"
      :rows = "articlesContent"
      :itemsPerPage = "itemsCount"
      :initialSortColumn = "sortBy"
      :sortDir = "sortDir"
      :userData="userData"
      :openWarning="openWarning"
      />


      
    </div>
</template>
<script>
  import DeleteWarning from '@/components/DeleteWarning';
  import moment from 'moment';
  import SortableTable from '@/components/SortableTable';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      SortableTable,
      DeleteWarning
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData:{ type: Function },
      userData: { required:true }
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        warning:{
          text:'Wollen Sie den Artikel wirklich löschen? Diese Aktion kann nicht widerrufen werden.',
          visible:false,
          deleteObject:{},
        },
        isVisible: true,
        itemsCount: 20,
        sortBy:"title",
        sortDir:'asc',
        colums:[
        {
            name:'datum',
            value:'created_at_unix',
            valueReadable:'created_at_readable',
            type:'date',
            visible:false
          },
          {
            name:'id',
            value:'id',
            type:'text',
            visible:true
          },
          {
            name:'title',
            value:'title',
            type:'text',
            visible:true
          },
        ],
       
        newArticle:{
          title:'',
          text:'',
          owner:''
        },
        
        articlesContent:[],
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
    

    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
     async mounted() {
      await this.getArticles();
      console.log(this.userData);
      this.newArticle.owner = this.userData.uuid;
      
     },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      openWarning(object){
        this.warning.visible = true;
        this.warning.deleteObject = object;
        console.log(object);
      },
      cancleWarning(){
        this.warning.visible = false;
      },
      async deleteArticle(object){
        let response;
        response = await this.apiData('ArticlesView.vue deleteArticle()','post','delete-article', object);
        console.log(response);
        await this.getArticles();
        this.warning.visible = false;
      },
    
      async addArticle(){
        let response;
        response = await this.apiData('ArticlesView.vue addArticle()','post','add-article', this.newArticle);
        console.log(response);
        await this.getArticles();
        //this.pageArray = [];
        //this.pagination();
      },
      async getArticles(){
        let response;
        response = await this.apiData('ArticlesView.vue getArticles()','get','articles');
        
        this.articlesContent = response;

        for(let i = 0 ; i < this.articlesContent.length; i++) {
            this.articlesContent[i].id = this.articlesContent[i].id.toString();
            this.articlesContent[i].created_at_readable = moment(String(this.articlesContent[i].created_at)).format('DD.MM.YYYY HH:mm');
            this.articlesContent[i].updated_at_readable = moment(String(this.articlesContent[i].updated_at)).format('DD.MM.YYYY HH:mm');
            this.articlesContent[i].created_at_unix = moment(String(this.articlesContent[i].created_at)).format('x');
            this.articlesContent[i].updated_at_unix = moment(String(this.articlesContent[i].updated_at)).format('x');
        }

        console.log(this.articlesContent);
        console.log(moment(String(this.articlesContent[0].created_at)).format('x'));
      },
     
    },
  };
</script>
<style lang="scss">
</style>