<template>
    <div>
      
      <div class="mb-3">
        <label for="nameField" class="form-label" >Name</label>
        <input type="text" :class="{'form-control': true, 'is-invalid': errors.name}"  id="nameField" v-model="user.name" >
        <div class="invalid-feedback" v-if="errors.name">
          <p v-for="(item, x) in errors.name" :key='x' >{{ errors.name[x] }}</p>
        </div>
      </div>

      <div class="mb-3">
        <label  for="emailField"  class="form-label">E-Mail</label>
        <input type="email" :class="{'form-control': true, 'is-invalid': errors.email}"  id="emailField" v-model="user.email" >
        <div class="invalid-feedback" v-if="errors.email">
          <p v-for="(item, x) in errors.email" :key='x' >{{ errors.email[x] }}</p>
        </div>
      </div>

      <div class="mb-3">
        <label  for="passwordField" class="form-label">Passwort</label>
        <input type="password" :class="{'form-control': true, 'is-invalid': errors.password}"  id="passwordField" v-model="user.password" >
        <password-meter :password="user.password" />
        <div class="invalid-feedback" v-if="errors.password">
          <p v-for="(item, x) in errors.password" :key='x' >{{ errors.password[x] }}</p>
        </div>
      </div>
      
      <div class="mb-3">
        <label for="confirmField" class="form-label">Passwort bestätigen</label>
        <input type="password" :class="{'form-control': true, 'is-invalid': errors.password_confirmation}"  id="confirmField" v-model="user.password_confirmation" >
        <div class="invalid-feedback" v-if="errors.password_confirmation">
          <p v-for="(item, x) in errors.password_confirmation" :key='x' >{{ errors.password_confirmation[x] }}</p>
        </div>
      </div>
      
      <div class="mb-3">
        <input type="checkbox" class="form-check-input" id="agbCheckbox"  v-model="agb">
        <label class="form-check-label" for="agbCheckbox">Ich akzeptiere die <a href="#" @click.prevent="agbShowFunction">Datenschutzverordnung</a> und die <a href="#" @click.prevent="agbShowFunction">AGB's.</a></label>
      </div>
      
      <div class="mb-3">
        <button v-on:click.prevent="signUp" :disabled="!user.name || !user.email || !user.password || !user.password_confirmation || agb === false" class="form-control btn btn-primary">Registrieren</button>
      </div>
        
    </div>
    {{ agbShowLocal }}
</template>
<script>
import PasswordMeter from 'vue-simple-password-meter';

  export default {
    
    name: '${COMPONENT_NAME}',
    components: {
      PasswordMeter
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: { type: Function },
      agbShowFunction: { type: Function }
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
  
        errors:{},
        success:false,
        agb:false,
        user:{
                    name:'',
                    email:'',
                    password:'',
                    password_confirmation:'',
                   
                },
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
       generateToken(){
        const randomNum = Math.random() * 9000
        return Math.floor(1000 + randomNum)
      },
      async signUp(){
        let response;
        response = await this.apiData('Register.vue signUp()','post','auth/signup', this.user);
        console.log(await response);
        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: 'Registrierung fehlerhaft',
            type: 'error',
            duration:3000,
          });
        }else{
          this.success = true;
          this.$toast.open({
            message: 'Registrierung erfolgreich',
            type: 'success',
            duration:3000,
          });
          this.$router.push('/login');
        }       
      }
    },
  };
</script>
<style lang="scss">
</style>