import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import Articles from '../views/ArticlesView.vue'
import Files from '../views/FilesView.vue'
import Imagga from '../views/ImaggaView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/articles',
    name: 'articles',
    component: Articles
  },
  {
    path: '/files',
    name: 'files',
    component: Files
  },
  {
    path: '/imagga',
    name: 'imagga',
    component: Imagga
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
