<template>

<!-- Login & Register Panel -->
<div class="card " v-if="isAuthenticated === false">
  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <a :class="{'nav-link':true,  'active': loginNav==='login' }" aria-current="true" href="#"  @click.prevent="loginNav = 'login'">Login</a>
      </li>
      <li class="nav-item">
        <a :class="{'nav-link':true,  'active': loginNav==='register' }" href="#" @click.prevent="loginNav = 'register'">Registrieren</a>
      </li>
    </ul>
  </div>
  <div class="card-body">
    <div class="row" v-if="loginNav === 'login'">
      <div class="col-md-6">
        <p>Login Text</p>
      </div>
      <div class="col-md-6">
        <Login 
        :setLoginStatus="setLoginStatus"
        :apiData="apiData"
        :setUserData="setUserData" 
        :authFunction="authFunction"
        />
      </div>
    </div>
    <div class="row" v-if="loginNav === 'register'">
      <div class="col-md-6">
        <p>Login Text</p>
      </div>
      <div class="col-md-6">
        <Register        
        :apiData="apiData"
        />
      </div>
    </div>
  </div>
</div>
<!-- END Login & Register Panel -->

<!-- Verification Panel -->
<div class="card text-center" v-if="isAuthenticated ===true && isVerified === false">
  <div class="card-header">
    <h5 class="card-title">E-Mail Adresse Bestätigen</h5>
  </div>
  <div class="card-body">
    
    <p class="card-text">Um Ihre E-Mail-Adresse zu verifizieren, geben Sie bitte den 4-stelligen Code ein, den wir Ihnen per E-Mail zugeschickt haben.</p>
    <h6 class="card-title">Code eingeben:</h6>
    <v-otp-input
      style="display: flex;justify-content: center;"
      ref="otpInput"
      input-classes="otp-input"
      :conditionalClass="['one', 'two', 'three', 'four']"
      separator="-"
      inputType="letter-numeric"
      :num-inputs="4"
      v-model:value="bindValue"
      :should-auto-focus="true"
      :should-focus-order="true"
      @on-complete="handleOnComplete"
    />
    <h5 class="text-danger" v-if="verifyError">Ihre eingabe ist falsch!</h5>
    <p class="card-text">Haben Sie keinen Code erhalten? Überprüfen Sie bitte Ihren Spam-Ordner oder <a href="#">fordern Sie einen neuen Code an.</a></p>
  </div>
</div>
<!-- END Verification Panel -->


<div class="card text-center">
  <div class="card-header">
    <h5 class="card-title">Controll Vars</h5>
  </div>
  <div class="card-body">
    
    <p class="card-text">is Autenticated {{ isAuthenticated }}</p>
    <p class="card-text">is Verified {{ isVerified }}</p>
    <p class="card-text">User Data {{ userData }}</p>
   
    
  </div>
</div>





  <div class="home">
  
  </div>
</template>
<script>
import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';
import VOtpInput from "vue3-otp-input";
export default {
  
  name: '${COMPONENT_NAME}',
  components: {

    VOtpInput,
    Login,
    Register
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    apiData: { type: Function },
    setLoginStatus: { type: Function },
    setUserData: { type: Function },
    authFunction: { type: Function },
    userData: { required: true },
    isAuthenticated: { required: true },
    isVerified: { required: true }
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      verifyError:false,
      userDataLocal: this.userData,
      loginNav:'login',
      bindValue:"",
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    async handleOnComplete(){
      let response;
      this.userDataLocal = this.userData;
      this.userDataLocal.code = this.bindValue;
      response = await this.apiData('Login.vue login()','post','auth/veryficate', this.userDataLocal);
      if(response === 1){
        this.authFunction();
        console.log('eingabe richtig');
        this.verifyError = false;
      }else{
        console.log('eingabe falsch')
        this.verifyError = true;
        
      }
      
    }
  },
};
</script>
<style lang="scss">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>

